import { apiClient as client } from '@/shared/service/api-client';

export default {
  async asyncAllGroupChatList(params) {
    return await client.get('/groupChat/asyncGroupChatList', { params });
  },
  async getLastSyncTime(params) {
    return await client.get('/groupChat/getLastSyncTime', { params });
  },
  async syncGroupChat(workWechatChatId) {
    return await client.get('/groupChat/syncGroupChat', { params: { workWechatChatId: workWechatChatId } });
  },
  async search(params) {
    return await client.get('/groupChat/search', { params });
  },
  async get(params) {
    return await client.get('/groupChat/get', { params });
  },
  async getGroupDetailList(params) {
    return await client.get('/groupChatMember/search', { params });
  },
}
