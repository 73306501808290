<template>
  <div class="group-detail">
    <router-header :routerHeaderInfo="routerHeaderInfo"></router-header>

    <a-row class="group-header">
      <a-col class="section"
             :span="12">
        <div class="group-info">

          <svg-icon :style="{fontSize: '72px'}"
                    type="iconqunliao" />
          <div class="group-leader-member">
            <p class="group-name__blod">
              <template v-if="groupInfo.name">
                {{groupInfo.name}}
              </template>
              <template v-else>
                <div v-is="'ww-open-data'"
                     class="ww-open-data"
                     type="userName"
                     :openid="groupInfo.ownerWorkWechatThirdAppUserId"></div>
                创建的群聊
              </template>
            </p>
            <a-space :size="8"
                     align="center">
              群主:
              <span>
                <avatar-column :avatar="{avatar: groupInfo.ownerAvatar, userId: groupInfo.ownerWorkWechatThirdAppUserId}"
                               :size="24"
                               round
                               is-user-id />
              </span>
              共{{groupInfo.totalAddCnt}}位成员
            </a-space>
          </div>
        </div>

        <div class="group-data__statistics">
          <a-row>
            <a-col :span="6"
                   v-for="data in groupDataStatistics"
                   :key="data.title">
              <a-statistic :title="data.title">
                <template #formatter>
                  <count-to :endVal="data.count"
                            :duration="1000"></count-to>
                </template>
              </a-statistic>
            </a-col>
          </a-row>
        </div>
      </a-col>
      <a-col class="section"
             :span="11"
             :push="1">
        <div class="flex-notice">
          <span class="group-notice">群公告</span>
          <div class="sync-button">
            <a-button type="link"
                      @click="handleSyncButton"
                      :disabled="isSync">
              <SyncOutlined :spin="isSync" />更新
            </a-button>
          </div>
        </div>
        <a-divider />
        <div v-if="groupInfo.notice"
             class="notice-content">{{groupInfo.notice}}</div>
        <a-empty v-else>
          <template #description>
            <span>
              暂无公告
            </span>
          </template>
        </a-empty>
      </a-col>
    </a-row>

    <div class="group-detail__table">
      <a-table :columns="columns"
               :data-source="group"
               :scroll="{ x: '100%' }"
               :pagination="pagination"
               row-key="workWechatChatId"
               @change="onPaginationChange"
               :loading="tableLoading">

        <template #nickname="{ record }">
          <avatar-column :avatar="{avatar: record.avatar, userId:  record.workWechatThirdAppUserId, name: record.nickname}"
                         :is-user-id="record.memberType === memberTypeEnum.STAFF">
            <template #bottom>
              <a-tag v-if="record.isOwner"
                     class=" py-0 px-8 mt-8 mb-0 border-none"
                     color="#31A1FF">群主
              </a-tag>
            </template>
          </avatar-column>
        </template>

        <template #memberType="{ record }">
          <span v-if="record.memberType === memberTypeEnum.STAFF">企业员工</span>
          <span v-else-if="record.memberType === memberTypeEnum.CUSTOMER">企业客户</span>
          <span v-else>未添加企业员工</span>
        </template>

        <template #joinTime="{ record }">
          <span>{{ $f.datetime(record.joinTime, 'YYYY-MM-DD HH:mm') }}</span>
        </template>

        <template #joinScene="{ record }">
          <span v-if="record.joinScene === 3 ">通过扫描群二维码入群</span>
          <span v-else>由成员直接邀请入群</span>
        </template>
        <template #action="{ record }">
          <div v-if="record.canManage">
            <router-link v-if="record.memberType === memberTypeEnum.STAFF"
                         :to="{path:'/enterprise/memberDetail',query:{ id: record.staffId}}">员工详情
            </router-link>
            <router-link v-else-if="record.memberType === memberTypeEnum.CUSTOMER"
                         :to="{path:'/customer/customerDetail',query:{ customerId: record.customerId, id: record.customerRelationId}}">
              客户详情
            </router-link>
          </div>
        </template>
      </a-table>
    </div>
  </div>
</template>

<script>
import { CountTo } from "vue-count-to2";
import { defineComponent, reactive } from "vue";
import { Statistic, Empty, Space } from "ant-design-vue";
import { useRoute, useRouter } from "vue-router";

import groupChatApi from "@/service/api/groupChat";

import RouterHeader from "@/components/RouterHeader.vue";
import AvatarColumn from "@/components/AvatarColumn";
import SvgIcon from "@/components/SvgIcon";

import { getWxAgentConfig } from "@/global";
import usePaginationQuery from "@/shared/composables/usePaginationQuery";

export default defineComponent({
  name: "groupDetail",
  components: {
    AStatistic: Statistic,
    AEmpty: Empty,
    ASpace: Space,
    CountTo,
    RouterHeader,
    AvatarColumn,
    SvgIcon,
  },

  data() {
    const groupInfo = {
      todayAddCnt: 0,
      todayLostCnt: 0,
      totalAddCnt: 0,
      totalCustomerCnt: 0,
    };
    return {
      isSync: false,
      groupInfo,
      groupDataStatistics: [],
    };
  },

  setup() {
    const $route = useRoute();
    const router = useRouter();

    const searchForm = reactive({
      chatId: $route.query.id,
    });

    const routerHeaderInfo = [
      {
        path: "/group/groupChat",
        name: "群列表",
      },
      { name: "列表详情" },
    ];

    const memberTypeEnum = {
      STAFF: 1,
      CUSTOMER: 2,
    };

    const columns = [
      {
        title: "群成员",
        dataIndex: "nickname",
        key: "nickname",
        slots: { customRender: "nickname" },
      },
      {
        title: "类型",
        dataIndex: "memberType",
        key: "memberType",
        slots: { customRender: "memberType" },
      },
      {
        title: "入群时间",
        dataIndex: "joinTime",
        key: "joinTime",
        slots: { customRender: "joinTime" },
      },
      {
        title: "入群方式",
        dataIndex: "joinScene",
        key: "joinScene",
        slots: { customRender: "joinScene" },
      },
      {
        title: "操作",
        key: "action",
        width: "13%",
        slots: { customRender: "action" },
      },
    ];

    const {
      rows,
      pagination,
      pullQueryParams,
      fetchPaginationData,
      onPaginationChange,
      tableLoading,
    } = usePaginationQuery(router, searchForm, groupChatApi.getGroupDetailList);

    getWxAgentConfig();

    return {
      searchForm,
      routerHeaderInfo,
      columns,
      memberTypeEnum,

      group: rows,
      pagination,
      pullQueryParams,
      fetchPaginationData,
      onPaginationChange,
      tableLoading,
    };
  },
  computed: {},

  mounted() {},
  created() {
    this.getGroupInfo();
  },
  methods: {
    handleSyncButton() {
      this.isSync = true;
      setTimeout(async () => {
        await groupChatApi.syncGroupChat(this.$route.query.id);
        this.getGroupInfo();
        this.$message.success("同步成功");
        this.isSync = false;
      }, 3000);
    },
    async getGroupInfo() {
      this.groupInfo = await groupChatApi.get({ id: this.$route.query.id });

      this.groupDataStatistics = [
        {
          title: "总人数",
          count: this.groupInfo.totalAddCnt,
        },
        {
          title: "总客户数",
          count: this.groupInfo.totalCustomerCnt,
        },
        {
          title: "今日入群",
          count: this.groupInfo.todayAddCnt,
        },
        {
          title: "今日退群",
          count: this.groupInfo.todayLostCnt,
        },
      ];
    },
  },
});
</script>

<style lang="less" scoped>
.group-detail__table {
  .group-leader-tag {
    padding: 0px @spacing-1x;
  }
}

.group-header {
  margin-bottom: @spacing-6x;

  .section {
    height: 257px;

    background-color: @bg-normal;
    padding: @spacing-6x;
  }

  .group-info {
    display: flex;

    .group-leader-member {
      width: 85%;
      margin-left: @spacing-3x;
      color: @gray-darker;
      .text-14();

      .group-ownerName {
        margin-left: @spacing-1x;
      }

      .group-name__blod {
        margin-top: @spacing-2x;
        margin-bottom: @spacing-4x;

        .text-bold();
        .text-overflow();
      }
    }
  }

  .group-data__statistics {
    margin-top: @spacing-6x;
    padding: @spacing-7x 0;
    border-radius: @border-radius;
    text-align: center;
    background-color: @bg;
  }

  .flex-notice {
    display: flex;
    justify-content: space-between;
    align-content: center;

    .group-notice {
      .text-16();
      .text-bold();
    }
    .sync-button {
      margin-bottom: 0;
    }
  }

  .ant-divider-horizontal {
    margin: @spacing-3x 0 @spacing-6x 0;
    background-color: @border;
  }

  .notice-content {
    overflow-y: auto;
    overflow-x: hidden;
    height: 130px;
    .text-16();
  }
}
.ant-btn-link {
  padding: 0;
}
</style>